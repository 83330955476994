<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters, mapState } from 'vuex';
import BackToolbar from '@/components/partials/BackToolbar';
import PointsEarnedModal from '@/components/Loyalty/PointsEarnedModal';
import dayjs from 'dayjs';

export default {
  name: 'Signup',
  props: ['visible'],
  components: {
    BackToolbar,
    PointsEarnedModal,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      client: null,
      registerForm: {
        email: '',
        contactNo: '',
        firstName: '',
        lastName: '',
        dob: '',
        password: '',
        confirmation: '',
        merchantId: 0,
        tnc: false,
      },
      attrs: [{
        key: 'selected',
        highlight: {
          class: 'vc-selected',
        },
        dates: new Date(),
      }],
      modelConfig: {
        timeAdjust: '00:00:00',
      },
      masks: {
        input: 'DD/MM/YYYY',
      },
      isLoading: false,
      show: false,
      error: null,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
  },
  methods: {
    ...mapActions('client', {
      createClient: 'create',
    }),
    ...mapActions('loyalty', {
      createLoyalty: 'create',
    }),
    async signup() {
      this.isLoading = true;

      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        return;
      }

      if (!this.registerForm.tnc) {
        this.error = 'Please tick to agree the terms & conditions.';
        setTimeout(() => this.error = null, 5000);
        this.isLoading = false;
        return;
      }

      try {
        this.$gtag.event('sign_up', {
          event_category: 'engagement',
          event_label: 'Sign up',
          event_value: `Email: ${this.registerForm.email}, Contact No: ${this.registerForm.contactNo}`,
        });

        this.registerForm.dob = dayjs(this.registerForm.dob).format('YYYY-MM-DD');
        this.client = await this.createClient(this.registerForm);
        
        if (this.client) {
          window.scrollTo(0,0);

          // join loyalty program
          await this.createLoyalty({
            merchantId: this.profile.merchant_id,
          });
        }

        this.isLoading = false;
      } catch (e) {
        console.log(e.message);
        this.isLoading = false;
        this.error = e.message;
        setTimeout(() => (this.error = null), 5000);
      }
    },
    close() {
      this.$gtag.event('sign_up', {
        event_category: 'engagement',
        event_label: 'Done',
      });

      this.$router.push({ name: 'login', query: { customRoute: 'home' } });
    }
  },
  mounted() {
    this.registerForm.merchantId = this.profile.merchant_id;
  },
};
</script>
<template>
  <div>
    <BackToolbar bgcolor="transparent" color="white" routename="home" customRoute=""></BackToolbar>
    <v-sheet
      class="signup-header-sheet mx-auto pt-3"
      elevation="0"
    >
      <v-row class="m-0 py-2">
        <v-col cols="12" v-if="!client">
          <h4 class="text-center white--text font-weight-bold">Not our member yet?</h4>
          <h2 class="text-center white--text font-weight-bold mt-4">Guest</h2>
          <div class="text-center white--text font-weight-6 mt-4">
            Join today & enjoy more rewards
          </div>
        </v-col>
        <v-col cols="12" v-else>
          <h4 class="text-center white--text font-weight-bold">Welcome!</h4>
          <h2 class="text-center tertiary--text font-weight-bold mt-4">{{ name }}</h2>
          <points-earned-modal :points="100" :image-height="'45vh'" :margin-top="20" route-name="home"></points-earned-modal>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet 
      class="signup-body-sheet mx-auto"
      elevation="0"
    >
      <ValidationObserver ref="form">
      <form>
        <v-card elevation="0">
          <v-card-title class="justify-center text-subtitle-1 font-weight-bold pb-2">
            Create your account
          </v-card-title>
          <v-card-text class="text-center pb-3">
            <v-row class="px-3">
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Name"
                  :rules="{ required: true, min: 3, max: 30 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.firstName"
                    class="register-form p-0 m-0"
                    placeholder="Name"
                    label="Name"
                    color="secondary"
                    autocomplete="first-name"
                    filled
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Contact No"
                  :rules="{ required: true, regex: /^\d{10,13}$/ }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.contactNo"
                    class="register-form p-0 m-0"
                    placeholder="Contact No"
                    label="Contact No"
                    color="primary"
                    autocomplete="contact-no"
                    filled
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.email"
                    class="register-form p-0 m-0"
                    placeholder="Email"
                    label="Email"
                    color="primary"
                    autocomplete="email"
                    filled
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <vc-date-picker
                  v-model="registerForm.dob"
                  mode="date"
                  color="primary"
                  :attributes="attrs"
                  :modelConfig="modelConfig"
                  :masks="masks"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      placeholder="Birthday"
                      class="register-form p-0 m-0"
                      v-on="inputEvents"
                      :value="inputValue"
                      append-icon="mdi-calendar-month"
                      label="Birthday"
                      filled
                      flat
                      rounded
                      solo
                      hide-details
                    ></v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  vid="password"
                  name="Password"
                  :rules="{ required: true, min: 8 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.password"
                    class="register-form p-0 m-0"
                    placeholder="Password"
                    label="Password"
                    color="primary"
                    autocomplete="off"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :error-messages="errors"
                    :success="valid"
                    filled
                    flat
                    rounded
                    solo
                    hide-details
                    @click:append="show = !show"
                  >
                  </v-text-field>
                  <ul
                    class="text-caption text-left error--text mt-2"
                    v-if="errors.length > 0"
                  >
                    <li>Min 8 characters</li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Confirm"
                  :rules="{ required: true, confirmed: 'password' }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.confirmation"
                    class="register-form p-0 m-0"
                    placeholder="Confirm your password"
                    label="Confirm"
                    color="primary"
                    autocomplete="off"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                    filled
                    flat
                    rounded
                    solo
                    @click:append="show = !show"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pt-0 pb-1">
                <v-checkbox
                  v-model="registerForm.tnc"
                  label="I agreed with the terms & conditions"
                  class="text-caption font-weight-6 ml-2 tnc"
                  color="success"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="mt-1">
                <v-btn
                  :disabled="isLoading || client"
                  color="buttonBackground"
                  class="text-body-2 font-weight-6 white--text w-75 py-6"
                  rounded
                  @click="signup()"
                  >Sign up</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </form>
      <v-alert
        v-if="error"
        class="alert-global text-body-2 white--text p-2"
        type="error"
        transition="fade-transition"
        dense
        >{{ error }}</v-alert
      >
    </ValidationObserver>
    </v-sheet>
  </div>
</template>
<style scoped>
.signup-header-sheet, .signup-success-sheet {
  background-color: var(--v-primary);
}

.signup-body-sheet {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.signup-body-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: 4px solid #E8E8E8;
}

.signup-body-sheet /deep/ .tnc .v-label {
  font-size: 14px !important;
  margin-top: 6px;
}

.alert-global {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>