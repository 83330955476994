var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BackToolbar',{attrs:{"bgcolor":"transparent","color":"white","routename":"home","customRoute":""}}),_c('v-sheet',{staticClass:"signup-header-sheet mx-auto pt-3",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"m-0 py-2"},[(!_vm.client)?_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-center white--text font-weight-bold"},[_vm._v("Not our member yet?")]),_c('h2',{staticClass:"text-center white--text font-weight-bold mt-4"},[_vm._v("Guest")]),_c('div',{staticClass:"text-center white--text font-weight-6 mt-4"},[_vm._v(" Join today & enjoy more rewards ")])]):_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-center white--text font-weight-bold"},[_vm._v("Welcome!")]),_c('h2',{staticClass:"text-center tertiary--text font-weight-bold mt-4"},[_vm._v(_vm._s(_vm.name))]),_c('points-earned-modal',{attrs:{"points":100,"image-height":'45vh',"margin-top":20,"route-name":"home"}})],1)],1)],1),_c('v-sheet',{staticClass:"signup-body-sheet mx-auto",attrs:{"elevation":"0"}},[_c('ValidationObserver',{ref:"form"},[_c('form',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"justify-center text-subtitle-1 font-weight-bold pb-2"},[_vm._v(" Create your account ")]),_c('v-card-text',{staticClass:"text-center pb-3"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":{ required: true, min: 3, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Name","label":"Name","color":"secondary","autocomplete":"first-name","filled":"","flat":"","rounded":"","solo":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.firstName),callback:function ($$v) {_vm.$set(_vm.registerForm, "firstName", $$v)},expression:"registerForm.firstName"}})]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Contact No","rules":{ required: true, regex: /^\d{10,13}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Contact No","label":"Contact No","color":"primary","autocomplete":"contact-no","filled":"","flat":"","rounded":"","solo":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.contactNo),callback:function ($$v) {_vm.$set(_vm.registerForm, "contactNo", $$v)},expression:"registerForm.contactNo"}})]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Email","label":"Email","color":"primary","autocomplete":"email","filled":"","flat":"","rounded":"","solo":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.email),callback:function ($$v) {_vm.$set(_vm.registerForm, "email", $$v)},expression:"registerForm.email"}})]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('vc-date-picker',{attrs:{"mode":"date","color":"primary","attributes":_vm.attrs,"modelConfig":_vm.modelConfig,"masks":_vm.masks,"is-required":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Birthday","value":inputValue,"append-icon":"mdi-calendar-month","label":"Birthday","filled":"","flat":"","rounded":"","solo":"","hide-details":""}},inputEvents))]}}]),model:{value:(_vm.registerForm.dob),callback:function ($$v) {_vm.$set(_vm.registerForm, "dob", $$v)},expression:"registerForm.dob"}})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":{ required: true, min: 8 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Password","label":"Password","color":"primary","autocomplete":"off","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"error-messages":errors,"success":valid,"filled":"","flat":"","rounded":"","solo":"","hide-details":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.registerForm.password),callback:function ($$v) {_vm.$set(_vm.registerForm, "password", $$v)},expression:"registerForm.password"}}),(errors.length > 0)?_c('ul',{staticClass:"text-caption text-left error--text mt-2"},[_c('li',[_vm._v("Min 8 characters")])]):_vm._e()]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Confirm","rules":{ required: true, confirmed: 'password' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Confirm your password","label":"Confirm","color":"primary","autocomplete":"off","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"hide-details":errors.length === 0,"error-messages":errors,"success":valid,"filled":"","flat":"","rounded":"","solo":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.registerForm.confirmation),callback:function ($$v) {_vm.$set(_vm.registerForm, "confirmation", $$v)},expression:"registerForm.confirmation"}})]}}])})],1),_c('v-col',{staticClass:"px-5 pt-0 pb-1",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"text-caption font-weight-6 ml-2 tnc",attrs:{"label":"I agreed with the terms & conditions","color":"success","hide-details":""},model:{value:(_vm.registerForm.tnc),callback:function ($$v) {_vm.$set(_vm.registerForm, "tnc", $$v)},expression:"registerForm.tnc"}})],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"text-body-2 font-weight-6 white--text w-75 py-6",attrs:{"disabled":_vm.isLoading || _vm.client,"color":"buttonBackground","rounded":""},on:{"click":function($event){return _vm.signup()}}},[_vm._v("Sign up")])],1)],1)],1)],1)],1),(_vm.error)?_c('v-alert',{staticClass:"alert-global text-body-2 white--text p-2",attrs:{"type":"error","transition":"fade-transition","dense":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }